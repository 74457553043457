import React from "react";
//import { Link } from "gatsby";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Timeline from "../../components/timeline";

const History = ({location}) => (
  <Layout location={location}>
    <SEO title="経歴" />
    <Timeline lang="ja" height="2000"
      items = {[
        {
          title: "Chaffey Community College",
          type: "education",
          side: "right",
          start: "2004-08-19",
          end: "2007-12-19",
          color: "#a80532",
          content: "準学士号：大学研究",
          bullets: [
            "成績平均点：4のうち3.99",
            "関連する授業：Maya、アジア史",
            "カリフォルニアにおける全ての大学・カレッジで通用する、一般教養を習得しました。"
          ]
        },
        {
          title: "Morrowind Graphics Extender",
          type: "project",
          side: "right",
          start: "2008-09-13",
          end: "2010-06-27",
          color: "#663D00",
          content: "このオープンソースのプロジェクトで、DirectXの関数呼び出しをあて先変更して、Morrowindのゲームのグラフィックを大幅に強化しました。",
          bullets: [
            "メモリおよびスレッド・プーリング、クアッドツリー・カリングなどを使用してコードを最適化しました。",
            "前にくらべて、フレームレートが500％増加しました。そのあと、ほとんどのユーザーが常に有効にする機能になりました。",
            "頂点シェーダーとピクセル・シェーダーを作成して、アニメーション化された草と水の効果を有効にしました。"
          ]
        },
        {
          title: "Durham Technical Community College",
          type: "education",
          side: "right",
          start: "2011-08-15",
          end: "2012-05-08",
          color: "#00843D",
          content: "関連する授業：微積分"
        },
        {
          title: "Wake Technical Community College",
          type: "education",
          side: "right",
          start: "2012-05-16",
          end: "2014-07-29",
          color: "#014165",
          content: "準学士号：プリエンジニアリング",
          bullets: [
            "成績平均点：4のうち4.0",
            "関連する授業：微積分、線形代数、物理学、Java、SolidWorksというＣＡＤプログラム"
          ]
        },
        {
          title: "ノース・カロライナ州立大学",
          type: "education",
          side: "right",
          start: "2014-08-21",
          end: "2017-05-13",
          color: "red",
          content: "学士号：ゲーム開発専門のコンピュータサイエンス",
          bullets: [
            "成績平均点：4のうち4.0",
            "関連する授業：ゲーム・AI、ゲーム・エンジン、3Dグラフィクス、離散数学、データ構造、オペレーティング・システム、クリエイティブ・ライティング",
            "使ったプログラミング言語：C、C++、C#、Assembly、GLSL、JavaScript、Java",
            "四人の学生と共にUnityでGoogle CardboardのVRのゲームを作りました。"
          ]
        },
        {
          title: "日本語能力試験N３級に合格した",
          type: "education",
          side: "right",
          start: "2017-12-01",
          end: "2017-12-01",
          color: "#d7123b "
        },
        {
          title: "Gameboyエミュレータ",
          type: "project",
          side: "right",
          start: "2018-09-16",
          end: "2019-08-05",
          color: "grey",
          content: "挑戦として、C++でエミュレータを作りました。",
          bullets: [
            "「Homebrew」と言う無料デモやテストを実行出来ます。",
            "白黒とカラーの両方の機能がサポートされています。",
            "音声チャネルは全部聞こえます。そしてDear ImGuiのUIで音声の波動も見えます。"
          ]
        },
        {
          title: "NifTools",
          type: "project",
          side: "left",
          start: "2005-09-01",
          end: "2007-12-01",
          color: "darkorange",
          content: "このオープンソースのプロジェクトで誰でも「Gamebryo」のゲーム・エンジンで作られたゲームの3Dモデルを改造出来る。",
          bullets: [
            "NIFファイルのデータ構造をリバースエンジニアリングしました。",
            "Mayaのインポーターとエクスポーターを開発しました。",
            "C++でNIFの変換用ライブラリを開発しました。"
          ]
        },
        {
          title: "Emergent Game Technologies",
          type: "work",
          side: "left",
          start: "2008-01-01",
          end: "2010-11-30",
          color: "#9ca534",
          content: "「Gamebryo」というC++の3Dゲーム・エンジンを発売した会社です。",
          bullets: [
            "ツールやサンプルやデモを改良したり開発しました。",
            "初心者からAAAまでのゲーム開発社のお客様を手伝いました。",
            "PS3、XBox 360とWiiでコードをデバッグしていました。",
            "お客様に相談するために、日本へ出張しました。"
          ]
        },
        {
          title: "Siemens Healthineers",
          type: "work",
          side: "left",
          start: "2010-12-01",
          end: "2018-07-31",
          color: "#009999",
          content: "「Process Manager」という3Dの医療研究室ソフトを開発しました。",
          bullets: [
            "C#のWPFのUIの完全な再設計を開発しました。",
            "3DS Maxで3Dモデルをつくりました。",
            "WebGLのプロトタイプの為に、GLSLシェーダを開発しました。"
          ]
        },
        {
          title: "上級エンジニアに昇進しました",
          side: "left",
          start: "2014-01-01",
          end: "2014-01-01",
          color: "#009999"
        },
        {
          title: "JETプログラム（外国青年招致事業）",
          type: "work",
          side: "left",
          start: "2018-07-29",
          end: "2020-09-15",
          color: "darkblue",
          content: "高校生に英語を教えました。",
          bullets: [
            "日本の生活と会社文化も慣れました。",
            "Node.jsのスクリプトで学校の複雑なスケジュールを計算しました。"
          ]
        }
      ]}
    />
  </Layout>
);

export default History;
